<template>
  <v-container class="pa-0" fluid>
    <kurcc-carousel v-if="showCarousel"/>
    <router-view/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccHomePage',
  components: {
    KurccCarousel: () => import('@/modules/home/components/KurccCarousel')
  },
  computed: {
    showCarousel () {
      return this.$route.name !== 'terms-of-service' && this.$route.name !== 'privacy-policy' && this.$route.name !== 'contact'
    }
  }
}
</script>
